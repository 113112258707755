var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    _vm._g(
      _vm._b(
        {
          staticClass: "timeline-item timeline-order",
          class: [
            "timeline-variant-" + _vm.variant,
            _vm.fillBorder ? "timeline-item-fill-border-" + _vm.variant : null,
          ],
        },
        "li",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.type
        ? _c("div", { staticClass: "timeline-item-point" })
        : _c(
            "div",
            {
              staticClass:
                "timeline-item-icon d-flex align-items-center justify-content-center rounded-circle",
              style: "border: 1px solid " + _vm.variant,
            },
            [
              _c("i", {
                class: "bi " + _vm.getIcon(_vm.type),
                style: "color: " + _vm.variant,
              }),
            ]
          ),
      _vm._t("default", function () {
        return [
          _c(
            "h6",
            {
              staticClass: "d-flex-between",
              style: "color: " + _vm.variant + "; ",
            },
            [
              _vm.type
                ? _c(
                    "a",
                    {
                      staticClass: "btn-link d-block text-nowrap",
                      attrs: { target: "_blank", href: "" + _vm.link },
                    },
                    [
                      _c("i", { staticClass: "bi bi-box-arrow-up-right" }),
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.title))]),
              _c("span", [
                _c(
                  "small",
                  { staticClass: "timeline-item-time text-nowrap text-dark" },
                  [_vm._v(" " + _vm._s(_vm.time) + " ")]
                ),
              ]),
            ]
          ),
          _vm.description
            ? _c("div", { staticClass: "text-timeline" }, [
                _vm.description.prefer_to_study
                  ? _c("span", { staticClass: "d-block mb-50" }, [
                      _c("strong", [_vm._v("Preferência:")]),
                      _vm._v(" " + _vm._s(_vm.description.prefer_to_study)),
                    ])
                  : _vm._e(),
                _vm.description.shift
                  ? _c("span", { staticClass: "d-block mb-50" }, [
                      _c("strong", [_vm._v("Turno:")]),
                      _vm._v(" " + _vm._s(_vm.description.shift)),
                    ])
                  : _vm._e(),
                _vm.description.unit
                  ? _c("span", { staticClass: "d-block mb-50" }, [
                      _c("strong", [_vm._v("Unidade:")]),
                      _vm._v(" " + _vm._s(_vm.description.unit)),
                    ])
                  : _vm._e(),
                _vm.description.time
                  ? _c("span", { staticClass: "d-block mb-50" }, [
                      _c("strong", [_vm._v("Turno:")]),
                      _vm._v(" " + _vm._s(_vm.description.time)),
                    ])
                  : _vm._e(),
                _vm.description.popup
                  ? _c("span", { staticClass: "d-block mb-50" }, [
                      _c("strong", [_vm._v("Origem:")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.description.popup ? "Pop-up" : "Página")
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm.text
            ? _c("div", { staticClass: "text-timeline" }, [
                _vm.text
                  ? _c("div", {
                      staticClass: "d-block mb-50",
                      domProps: { innerHTML: _vm._s(_vm.text) },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }