export default {
  methods: {
    handleRouteClick(event, route) {
      const url = this.$router.resolve(route).href;

      if (event.ctrlKey || event.metaKey) {
        window.open(url, "_blank");
      } else {
        this.$router.push(route);
      }
    },
  },
};
