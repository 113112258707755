<template>
  <li
    v-bind="$attrs"
    class="timeline-item timeline-order"
    :class="[
      `timeline-variant-${variant}`,
      fillBorder ? `timeline-item-fill-border-${variant}` : null,
    ]"
    v-on="$listeners"
  >
    <div v-if="!type" class="timeline-item-point" />
    <div
      v-else
      :style="`border: 1px solid ${variant}`"
      class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
    >
      <i :style="`color: ${variant}`" :class="`bi ${getIcon(type)}`"></i>
    </div>

    <slot>
      <h6 class="d-flex-between" :style="`color: ${variant}; `">
        <a
          v-if="type"
          class="btn-link d-block text-nowrap"
          target="_blank"
          :href="`${link}`"
        >
          <i class="bi bi-box-arrow-up-right"></i>
          {{ title }}
        </a>
        <span v-else>{{ title }}</span>
        <span>
          <small class="timeline-item-time text-nowrap text-dark">
            {{ time }}
          </small>
        </span>
      </h6>

      <div v-if="description" class="text-timeline">
        <span v-if="description.prefer_to_study" class="d-block mb-50"
          ><strong>Preferência:</strong> {{ description.prefer_to_study }}</span
        >
        <span class="d-block mb-50" v-if="description.shift"
          ><strong>Turno:</strong> {{ description.shift }}</span
        >
        <span class="d-block mb-50" v-if="description.unit"
          ><strong>Unidade:</strong> {{ description.unit }}</span
        >
        <span class="d-block mb-50" v-if="description.time"
          ><strong>Turno:</strong> {{ description.time }}</span
        >
        <span v-if="description.popup" class="d-block mb-50"
          ><strong>Origem:</strong>
          {{ description.popup ? "Pop-up" : "Página" }}</span
        >
      </div>
      <div v-else-if="text" class="text-timeline">
        <div v-if="text" v-html="text" class="d-block mb-50"></div>
      </div>
    </slot>
  </li>
</template>

<script>
export default {
  data() {
    return {
      link: "",
    };
  },
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    domain: {
      type: String,
      default: null,
    },
    description: {
      type: Object,
      default: null,
    },
    contest: {
      type: Object,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    fillBorder: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getIcon(type) {
      let icon = "";
      switch (type) {
        case "contest":
          icon = "bi-journal-text";
          break;
        case "product":
          icon = "bi-box2-heart";
          break;
        default:
          icon = "bi-info-circle";
          break;
      }

      return icon;
    },
  },
  mounted() {
    if (this.type === "contest") {
      this.link = this.domain + '/concursos/' + this.contest.slug;
    }

    if (this.type === "product") {
      this.link = this.domain + this.product.slug;
    }
  },
};
</script>

<style lang="scss" scoped>
.timeline-order {
  padding-bottom: 1rem !important;
  h6 {
    margin-bottom: 10px;
    font-size: 1rem;
    small {
      font-size: 0.9rem;
    }
  }

  .text-timeline {
    margin-top: 5px;
    color: #000;
    opacity: 0.8;
    font-size: 0.9rem;

    small {
      font-size: 0.9rem !important;
      display: block;
    }
  }
}

.timeline-order:last-child {
  padding-bottom: 0px !important;
}

@import "~@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "~@core/scss/base/components/include"; // Components includes

// Color palettes
@import "~@core/scss/base/core/colors/palette-variables.scss";

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== "base" {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;
          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }
        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: "";
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -15px;
    top: 0;
    width: 30px;
    height: 30px;
    background-color: $white;
    z-index: 1;
    line-height: 2;

    .bi {
      font-size: 1.2rem;
    }
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient(
          $theme-dark-border-color,
          $theme-dark-card-bg
        );
      }
    }
    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
