var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.notFound
      ? _c("div", { staticClass: "alert alert-danger p-2" }, [
          _vm._v(" Nenhum cliente encontrado. "),
          _c("br"),
          _vm._v(" Entre em contato com o suporte da Seducar. "),
        ])
      : _c(
          "div",
          [
            !_vm.item.external_id
              ? _c("div", { staticClass: "alert alert-danger p-2" }, [
                  _vm._m(0),
                  _vm._m(1),
                ])
              : _vm._e(),
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitUpdate.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "form-row" },
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "8" } },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mb-2",
                            attrs: { header: "Informações básicas" },
                          },
                          [
                            _c(
                              "b-card-text",
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "form-row" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "text-danger bi bi-record-circle",
                                                }),
                                                _vm._v(" Nome completo "),
                                              ]
                                            ),
                                            _c("b-form-input", {
                                              class: {
                                                "is-invalid":
                                                  _vm.$v.item.full_name.$error,
                                              },
                                              model: {
                                                value: _vm.item.full_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "full_name",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.full_name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3" } },
                                      [
                                        _c("b-form-group", [
                                          _c("label", { attrs: { for: "" } }, [
                                            _c("i", {
                                              staticClass:
                                                "text-danger bi bi-record-circle",
                                            }),
                                            _vm._v(" CPF "),
                                          ]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: ["###.###.###-##"],
                                                expression:
                                                  "['###.###.###-##']",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.item.cpf,
                                                expression: "item.cpf",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid":
                                                _vm.$v.item.cpf.$error,
                                            },
                                            attrs: {
                                              type: "tel",
                                              name: "cpf",
                                              placeholder: "000.000.000-00",
                                            },
                                            domProps: { value: _vm.item.cpf },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.item,
                                                  "cpf",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3", cols: "6" } },
                                      [
                                        _c("b-form-group", [
                                          _c("label", { attrs: { for: "" } }, [
                                            _c("i", {
                                              staticClass:
                                                "text-danger bi bi-record-circle",
                                            }),
                                            _vm._v(" Celular válido "),
                                          ]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: [
                                                  "(##) #####-####",
                                                  "(##) ####-####",
                                                ],
                                                expression:
                                                  "['(##) #####-####', '(##) ####-####']",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.item.cellphone,
                                                expression: "item.cellphone",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid":
                                                _vm.$v.item.cellphone.$error,
                                            },
                                            attrs: {
                                              type: "tel",
                                              name: "cpf",
                                              placeholder: "(00) 00000-0000",
                                            },
                                            domProps: {
                                              value: _vm.item.cellphone,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.item,
                                                  "cellphone",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "6", cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "text-danger bi bi-record-circle",
                                                }),
                                                _vm._v(" E-mail "),
                                              ]
                                            ),
                                            _c("b-form-input", {
                                              class: {
                                                "is-invalid":
                                                  _vm.$v.item.email.$error,
                                              },
                                              attrs: { type: "email" },
                                              model: {
                                                value: _vm.item.email,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.email",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3", cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [_vm._v(" Nascimento ")]
                                            ),
                                            _c("flat-pickr", {
                                              staticClass: "form-control",
                                              attrs: {
                                                config: {
                                                  altInput: true,
                                                  dateFormat: "Y-m-d",
                                                  altFormat: "d/m/Y",
                                                  locale: "pt",
                                                },
                                              },
                                              model: {
                                                value: _vm.item.birth,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "birth",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.birth",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { md: "3", cols: "6" } },
                                      [
                                        _c("b-form-group", [
                                          _c(
                                            "label",
                                            {
                                              class: !_vm.item.external_id
                                                ? "text-danger"
                                                : "",
                                            },
                                            [_vm._v(" ID Vindi ")]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.item.external_id,
                                                expression: "item.external_id",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              disabled: "",
                                              type: "tel",
                                            },
                                            domProps: {
                                              value: _vm.item.external_id,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.item,
                                                  "external_id",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("hr", { staticClass: "my-2" }),
                                _c("b-row", { staticClass: "form-row mt-1" }, [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("label", [
                                      _vm._v(" Senha "),
                                      _c("small", [
                                        _vm._v("Min. 6 max. 10 caractéres."),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-input", {
                                              staticClass: "form-control-merge",
                                              attrs: {
                                                id: "senha",
                                                type: _vm.passwordFieldType,
                                                autocomplete: "nop",
                                              },
                                              model: {
                                                value: _vm.item.password,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "password",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.password",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              { attrs: { "is-text": "" } },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "cursor-pointer",
                                                  attrs: {
                                                    icon: _vm.passwordToggleIcon,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.togglePasswordVisibility,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("label", [
                                      _vm._v(" Confirmação de senha "),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "input-group-merge" },
                                          [
                                            _c("b-form-input", {
                                              staticClass: "form-control-merge",
                                              attrs: {
                                                id: "confirmation_senha",
                                                type: _vm.passwordFieldType,
                                              },
                                              model: {
                                                value:
                                                  _vm.item
                                                    .password_confirmation,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.item,
                                                    "password_confirmation",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.password_confirmation",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              { attrs: { "is-text": "" } },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "cursor-pointer",
                                                  attrs: {
                                                    icon: _vm.passwordToggleIcon,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.togglePasswordVisibility,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mb-1",
                            attrs: { header: "Endereço" },
                          },
                          [
                            _c(
                              "b-card-text",
                              [
                                _c("b-row", { staticClass: "form-row" }, [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" CEP "),
                                    ]),
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["#####-###"],
                                            expression: "['#####-###']",
                                          },
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.cep,
                                            expression: "item.cep",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.item.cep },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.searchCep()
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item,
                                              "cep",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Número "),
                                    ]),
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.number,
                                            expression: "item.number",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.item.number },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item,
                                              "number",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(" Complemento "),
                                    ]),
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.complement,
                                            expression: "item.complement",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.item.complement,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item,
                                              "complement",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" UF "),
                                    ]),
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.uf,
                                            expression: "item.uf",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.item.uf },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item,
                                              "uf",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "col-md-12" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Endereço "),
                                    ]),
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.item.address,
                                            expression: "item.address",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.item.address },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.item,
                                              "address",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Bairro "),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "form-group mb-md-0" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.item.neighborhood,
                                              expression: "item.neighborhood",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.item.neighborhood,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.item,
                                                "neighborhood",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Cidade "),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "form-group mb-md-0" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.item.city,
                                              expression: "item.city",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.item.city },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.item,
                                                "city",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-tabs",
                  { staticClass: "mt-3", attrs: { fill: "", pills: "" } },
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Pedidos " + _vm.orders.length,
                          active: "",
                        },
                      },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "b-card",
                              [
                                _vm.orders.length
                                  ? _c("b-table", {
                                      staticClass: "position-relative pb-0",
                                      attrs: {
                                        items: _vm.orders,
                                        responsive: "",
                                        fields: _vm.fields,
                                        "show-empty": "",
                                        "empty-text":
                                          "Nenhum registro encontrado",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "cell(classroom_name)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getProductField(
                                                        data.item,
                                                        "classroom_name"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(product_category_name)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getProductField(
                                                        data.item,
                                                        "product_category_name"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(product_title)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getProductField(
                                                        data.item,
                                                        "title"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(method)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      data.item.method.name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(total)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("toCurrency")(
                                                        data.item.total
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(status)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "badge",
                                                    class:
                                                      data.item.status.name ===
                                                      "Pago"
                                                        ? "badge-light-success"
                                                        : "badge-light-dark",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.status.name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(quantity)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      data.item.products.length
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(created_at)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("datePT")(
                                                        data.item.created_at,
                                                        true
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(actions)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-6" },
                                                      [
                                                        _c("feather-icon", {
                                                          staticClass:
                                                            "cursor-pointer cursor",
                                                          attrs: {
                                                            icon: "EyeIcon",
                                                            size: "16",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleRouteClick(
                                                                $event,
                                                                {
                                                                  name: "transactions-orders-preview",
                                                                  params: {
                                                                    uuid: data
                                                                      .item
                                                                      .uuid,
                                                                  },
                                                                }
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3726566828
                                      ),
                                    })
                                  : _c("div", { staticClass: "form-row" }, [
                                      _c("div", { staticClass: "col-md-12" }, [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " Nenhum item foi adicionado ao carrinho até o momento. "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title:
                            "Carrinho (" +
                            (_vm.cart ? _vm.cart.total_items.length : 0) +
                            ")",
                        },
                      },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c("b-card", [
                              _vm.cart
                                ? _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-9" },
                                      [
                                        _vm.cart.products &&
                                        _vm.cart.products.length
                                          ? _c("b-table", {
                                              staticClass:
                                                "position-relative pb-0",
                                              attrs: {
                                                items: _vm.cart.products,
                                                responsive: "",
                                                fields: _vm.fieldsCart,
                                                "primary-key": "id",
                                                "show-empty": "",
                                                "empty-text":
                                                  "Nenhum registro encontrado",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "cell(title)",
                                                    fn: function (data) {
                                                      return [
                                                        _c(
                                                          "b-media",
                                                          {
                                                            attrs: {
                                                              "vertical-align":
                                                                "center",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "aside",
                                                                  fn: function () {
                                                                    return [
                                                                      data.item
                                                                        .image
                                                                        ? _c(
                                                                            "b-avatar",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  size: "32",
                                                                                  src: data
                                                                                    .item
                                                                                    .image,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "b-avatar",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  rounded:
                                                                                    "sm",
                                                                                  variant:
                                                                                    "light-secondary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "feather-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "ImageIcon",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "d-block text-nowrap",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "bi bi-circle-fill mr-50",
                                                                  class: data
                                                                    .item.status
                                                                    ? "text-success"
                                                                    : "text-danger",
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "11px",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        "https://" +
                                                                        _vm.user
                                                                          .school
                                                                          .domain +
                                                                        data
                                                                          .item
                                                                          .path,
                                                                      target:
                                                                        "_blank",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .title
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c("small", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-50",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      data.item
                                                                        .category
                                                                        .name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-50",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Quant.: " +
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .quantity
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(price)",
                                                    fn: function (data) {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "toCurrency"
                                                              )(data.item.price)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(quantity)",
                                                    fn: function (data) {
                                                      return [
                                                        data.item.quantity
                                                          ? _c(
                                                              "b-badge",
                                                              {
                                                                staticClass:
                                                                  "ml-1 py-75 d-block",
                                                                attrs: {
                                                                  variant:
                                                                    "light-dark",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .quantity
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "cell(created_at)",
                                                    fn: function (data) {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f("datePT")(
                                                                data.item
                                                                  .created_at,
                                                                true
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                400760998
                                              ),
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "col-md-3" }, [
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "list-group list-group-flush",
                                        },
                                        [
                                          _c(
                                            "li",
                                            { staticClass: "list-group-item" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-7" },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          "Quant. Produtos:"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-5 text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.cart.total_items
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "li",
                                            { staticClass: "list-group-item" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-6" },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("Desconto:"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-6 text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "toCurrency"
                                                            )(
                                                              _vm.cart
                                                                .total_discount
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _vm.cart.coupon
                                                        ? _c(
                                                            "b-badge",
                                                            {
                                                              staticClass:
                                                                "py-50",
                                                              attrs: {
                                                                variant:
                                                                  "light-dark",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.typeDiscount(
                                                                      _vm.cart
                                                                        .coupon
                                                                        .value_discount,
                                                                      _vm.cart
                                                                        .coupon
                                                                        .type_discount
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "li",
                                            { staticClass: "list-group-item" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-7" },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("Subtotal:"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-md-5 text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "toCurrency"
                                                            )(
                                                              _vm.cart
                                                                .total_price
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _c("div", { staticClass: "form-row" }, [
                                    _c("div", { staticClass: "col-md-12" }, [
                                      _c("p", { staticClass: "my-0" }, [
                                        _vm._v(
                                          " Nenhum item foi adicionado ao carrinho até o momento. "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Interesses (" + _vm.interesteds.length + ")",
                        },
                      },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "b-card",
                              [
                                _vm.interesteds.length
                                  ? _c(
                                      "app-timeline",
                                      _vm._l(
                                        _vm.interesteds,
                                        function (interested, index) {
                                          return interested.description ||
                                            interested.contest ||
                                            interested.product ||
                                            interested.page_title ||
                                            interested.interested.type
                                            ? _c("app-timeline-item", {
                                                key: index,
                                                attrs: {
                                                  contest: interested.contest,
                                                  product: interested.product,
                                                  domain:
                                                    "https://" +
                                                    _vm.user.school.domain,
                                                  title: interested.page_title,
                                                  description:
                                                    interested.description,
                                                  type: interested.type,
                                                  time: _vm._f("datePT")(
                                                    interested.created_at,
                                                    true
                                                  ),
                                                  variant: "dark",
                                                },
                                              })
                                            : _vm._e()
                                        }
                                      ),
                                      1
                                    )
                                  : _c("p", { staticClass: "my-0" }, [
                                      _vm._v("Nenhum interesse até o momento"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Follows (" + _vm.follows.length + ")",
                        },
                      },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "b-card",
                              [
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.saveFollow.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: "",
                                          "label-for": "follow-textarea",
                                        },
                                      },
                                      [
                                        _c("b-form-textarea", {
                                          attrs: {
                                            id: "follow-textarea",
                                            placeholder: "Digite o texto...",
                                            rows: "3",
                                          },
                                          model: {
                                            value: _vm.follow.text,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.follow, "text", $$v)
                                            },
                                            expression: "follow.text",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "primary",
                                          type: "submit",
                                          disabled: _vm.loading,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.loading
                                                ? "Salvando..."
                                                : "Salvar"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("hr", { staticClass: "my-2" }),
                                _vm.follows.length
                                  ? _c(
                                      "app-timeline",
                                      _vm._l(
                                        _vm.follows,
                                        function (follow, index) {
                                          return _c("app-timeline-item", {
                                            key: index,
                                            attrs: {
                                              text: follow.text,
                                              time: _vm._f("datePT")(
                                                follow.created_at,
                                                true
                                              ),
                                              variant: "dark",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _c("p", { staticClass: "my-0" }, [
                                      _vm._v("Nenhum follows até o momento"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-row justify-content-end modal-footer-custom",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-3 col-12" },
                      [
                        _c("ButtonsActionsFooter", {
                          attrs: {
                            routerBack: "customers-list",
                            variant: "success",
                            submited: _vm.submited,
                            text: "Salvar",
                            subtext: "Aguarde...",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" O cliente não possui "),
      _c("strong", [_vm._v("ID")]),
      _vm._v(" de cadastro na "),
      _c("strong", [_vm._v("VINDI")]),
      _vm._v(". O cliente não conseguirá realizar compra. "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-0" }, [
      _vm._v(" Para cadatrar o cliente na Vindi, preencha os dados do "),
      _c("strong", [_vm._v("ENDEREÇO")]),
      _vm._v(". A Seducar vai tentar efetuar o cadastro do msmo na Vindi. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }