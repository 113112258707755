<template>
  <div>
    <div v-if="notFound" class="alert alert-danger p-2">
      Nenhum cliente encontrado. <br />
      Entre em contato com o suporte da Seducar.
    </div>
    <div v-else>
      <div class="alert alert-danger p-2" v-if="!item.external_id">
        <p>
          O cliente não possui <strong>ID</strong> de cadastro na
          <strong>VINDI</strong>. O cliente não conseguirá realizar compra.
        </p>
        <p class="mb-0">
          Para cadatrar o cliente na Vindi, preencha os dados do
          <strong>ENDEREÇO</strong>. A Seducar vai tentar efetuar o cadastro do
          msmo na Vindi.
        </p>
      </div>
      <b-form @submit.prevent="submitUpdate">
        <div class="form-row">
          <b-col md="8">
            <b-card header="Informações básicas" class="mb-2">
              <b-card-text>
                <b-row class="form-row">
                  <b-col md="6">
                    <b-form-group>
                      <label for="">
                        <i class="text-danger bi bi-record-circle"></i>
                        Nome completo
                      </label>
                      <b-form-input
                        v-model="item.full_name"
                        :class="{ 'is-invalid': $v.item.full_name.$error }"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group>
                      <label for="">
                        <i class="text-danger bi bi-record-circle"></i>
                        CPF
                      </label>
                      <input
                        v-mask="['###.###.###-##']"
                        type="tel"
                        v-model="item.cpf"
                        :class="{ 'is-invalid': $v.item.cpf.$error }"
                        name="cpf"
                        placeholder="000.000.000-00"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="3" cols="6">
                    <b-form-group>
                      <label for="">
                        <i class="text-danger bi bi-record-circle"></i>
                        Celular válido
                      </label>
                      <input
                        v-mask="['(##) #####-####', '(##) ####-####']"
                        type="tel"
                        v-model="item.cellphone"
                        :class="{ 'is-invalid': $v.item.cellphone.$error }"
                        name="cpf"
                        placeholder="(00) 00000-0000"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="6">
                    <b-form-group>
                      <label for="">
                        <i class="text-danger bi bi-record-circle"></i>
                        E-mail
                      </label>
                      <b-form-input
                        type="email"
                        v-model="item.email"
                        :class="{ 'is-invalid': $v.item.email.$error }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3" cols="6">
                    <b-form-group>
                      <label for=""> Nascimento </label>
                      <flat-pickr
                        v-model="item.birth"
                        class="form-control"
                        :config="{
                          altInput: true,
                          dateFormat: 'Y-m-d',
                          altFormat: 'd/m/Y',
                          locale: 'pt',
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3" cols="6">
                    <b-form-group>
                      <label :class="!item.external_id ? 'text-danger' : ''">
                        ID Vindi
                      </label>
                      <input
                        disabled
                        type="tel"
                        v-model="item.external_id"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr class="my-2" />
                <b-row class="form-row mt-1">
                  <div class="col-md-6">
                    <label>
                      Senha
                      <small>Min. 6 max. 10 caractéres.</small>
                    </label>
                    <div class="form-group">
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="senha"
                          v-model="item.password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          autocomplete="nop"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label> Confirmação de senha </label>
                    <div class="form-group">
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="confirmation_senha"
                          v-model="item.password_confirmation"
                          class="form-control-merge"
                          :type="passwordFieldType"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
          </b-col>
          <div class="col-md-4">
            <b-card header="Endereço" class="mb-1">
              <b-card-text>
                <b-row class="form-row">
                  <div class="col-md-6">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      CEP
                    </label>
                    <div class="form-group">
                      <input
                        @keyup="searchCep()"
                        v-mask="['#####-###']"
                        type="text"
                        v-model="item.cep"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Número
                    </label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="item.number"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for=""> Complemento </label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="item.complement"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      UF
                    </label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="item.uf"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Endereço
                    </label>
                    <div class="form-group">
                      <input
                        type="text"
                        v-model="item.address"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Bairro
                    </label>
                    <div class="form-group mb-md-0">
                      <input
                        type="text"
                        v-model="item.neighborhood"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Cidade
                    </label>
                    <div class="form-group mb-md-0">
                      <input
                        type="text"
                        v-model="item.city"
                        class="form-control"
                      />
                    </div>
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
          </div>
        </div>

        <b-tabs fill pills class="mt-3">
          <b-tab :title="`Pedidos ${orders.length}`" active>
            <b-card-text>
              <b-card>
                <b-table
                  v-if="orders.length"
                  :items="orders"
                  responsive
                  :fields="fields"
                  show-empty
                  empty-text="Nenhum registro encontrado"
                  class="position-relative pb-0"
                >
                  <template #cell(classroom_name)="data">
                    {{ getProductField(data.item, "classroom_name") }}
                  </template>

                  <template #cell(product_category_name)="data">
                    {{ getProductField(data.item, "product_category_name") }}
                  </template>

                  <template #cell(product_title)="data">
                    {{ getProductField(data.item, "title") }}
                  </template>

                  <template #cell(method)="data">
                    {{ data.item.method.name }}
                  </template>

                  <template #cell(total)="data">
                    {{ data.item.total | toCurrency }}
                  </template>

                  <template #cell(status)="data">
                    <span
                      :class="
                        data.item.status.name === 'Pago'
                          ? 'badge-light-success'
                          : 'badge-light-dark'
                      "
                      class="badge"
                    >
                      {{ data.item.status.name }}
                    </span>
                  </template>

                  <template #cell(quantity)="data">
                    {{ data.item.products.length }}
                  </template>

                  <template #cell(created_at)="data">
                    {{ data.item.created_at | datePT(true) }}
                  </template>
                  <template #cell(actions)="data">
                    <div class="form-row">
                      <div class="col-6">
                        <feather-icon
                          icon="EyeIcon"
                          size="16"
                          class="cursor-pointer cursor"
                          @click="
                            handleRouteClick($event, {
                              name: 'transactions-orders-preview',
                              params: { uuid: data.item.uuid },
                            })
                          "
                        />
                      </div>
                    </div>
                  </template>
                </b-table>
                <div class="form-row" v-else>
                  <div class="col-md-12">
                    <p class="mb-0">
                      Nenhum item foi adicionado ao carrinho até o momento.
                    </p>
                  </div>
                </div>
              </b-card>
            </b-card-text>
          </b-tab>

          <b-tab :title="`Carrinho (${cart ? cart.total_items.length : 0})`">
            <b-card-text>
              <b-card>
                <div class="form-row" v-if="cart">
                  <div class="col-md-9">
                    <b-table
                      v-if="cart.products && cart.products.length"
                      :items="cart.products"
                      responsive
                      :fields="fieldsCart"
                      primary-key="id"
                      show-empty
                      empty-text="Nenhum registro encontrado"
                      class="position-relative pb-0"
                    >
                      <template #cell(title)="data">
                        <b-media vertical-align="center">
                          <template #aside>
                            <b-avatar
                              v-if="data.item.image"
                              size="32"
                              :src="data.item.image"
                            />
                            <b-avatar
                              v-else
                              rounded="sm"
                              variant="light-secondary"
                            >
                              <feather-icon icon="ImageIcon" />
                            </b-avatar>
                          </template>
                          <span class="d-block text-nowrap">
                            <i
                              style="font-size: 11px"
                              class="bi bi-circle-fill mr-50"
                              :class="
                                data.item.status
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            ></i>
                            <a
                              :href="`https://${user.school.domain}${data.item.path}`"
                              target="_blank"
                            >
                              {{ data.item.title }}
                            </a>
                          </span>
                          <small>
                            <span class="mr-50">{{
                              data.item.category.name
                            }}</span>
                            <span class="mr-50"
                              >Quant.: {{ data.item.quantity }}</span
                            >
                          </small>
                        </b-media>
                      </template>

                      <template #cell(price)="data">
                        {{ data.item.price | toCurrency }}
                      </template>

                      <template #cell(quantity)="data">
                        <b-badge
                          class="ml-1 py-75 d-block"
                          v-if="data.item.quantity"
                          variant="light-dark"
                        >
                          {{ data.item.quantity }}
                        </b-badge>
                      </template>

                      <template #cell(created_at)="data">
                        {{ data.item.created_at | datePT(true) }}
                      </template>
                    </b-table>
                  </div>
                  <div class="col-md-3">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div class="form-row">
                          <div class="col-md-7">
                            <strong>Quant. Produtos:</strong>
                          </div>
                          <div class="col-md-5 text-right">
                            {{ cart.total_items }}
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="form-row">
                          <div class="col-md-6">
                            <strong>Desconto:</strong>
                          </div>
                          <div class="col-md-6 text-right">
                            {{ cart.total_discount | toCurrency }}
                            <b-badge
                              class="py-50"
                              v-if="cart.coupon"
                              variant="light-dark"
                            >
                              {{
                                typeDiscount(
                                  cart.coupon.value_discount,
                                  cart.coupon.type_discount
                                )
                              }}
                            </b-badge>
                          </div>
                        </div>
                      </li>

                      <li class="list-group-item">
                        <div class="form-row">
                          <div class="col-md-7">
                            <strong>Subtotal:</strong>
                          </div>
                          <div class="col-md-5 text-right">
                            {{ cart.total_price | toCurrency }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="form-row" v-else>
                  <div class="col-md-12">
                    <p class="my-0">
                      Nenhum item foi adicionado ao carrinho até o momento.
                    </p>
                  </div>
                </div>
              </b-card>
            </b-card-text>
          </b-tab>

          <b-tab :title="`Interesses (${interesteds.length})`">
            <b-card-text>
              <b-card>
                <app-timeline v-if="interesteds.length">
                  <app-timeline-item
                    v-for="(interested, index) in interesteds"
                    :key="index"
                    v-if="
                      interested.description ||
                      interested.contest ||
                      interested.product ||
                      interested.page_title ||
                      interested.interested.type
                    "
                    :contest="interested.contest"
                    :product="interested.product"
                    :domain="`https://${user.school.domain}`"
                    :title="interested.page_title"
                    :description="interested.description"
                    :type="interested.type"
                    :time="interested.created_at | datePT(true)"
                    variant="dark"
                  />
                </app-timeline>
                <p v-else class="my-0">Nenhum interesse até o momento</p>
              </b-card>
            </b-card-text>
          </b-tab>

          <b-tab :title="`Follows (${follows.length})`">
            <b-card-text>
              <b-card>
                <b-form @submit.prevent="saveFollow">
                  <b-form-group label="" label-for="follow-textarea">
                    <b-form-textarea
                      id="follow-textarea"
                      v-model="follow.text"
                      placeholder="Digite o texto..."
                      rows="3"
                    ></b-form-textarea>
                  </b-form-group>

                  <b-button variant="primary" type="submit" :disabled="loading">
                    {{ loading ? "Salvando..." : "Salvar" }}
                  </b-button>
                </b-form>

                <hr class="my-2" />

                <app-timeline v-if="follows.length">
                  <app-timeline-item
                    v-for="(follow, index) in follows"
                    :key="index"
                    :text="follow.text"
                    :time="follow.created_at | datePT(true)"
                    variant="dark"
                  />
                </app-timeline>

                <p v-else class="my-0">Nenhum follows até o momento</p>
              </b-card>
            </b-card-text>
          </b-tab>

          <!-- <b-tab title="Mensagens">
            <b-card-text>
              <b-card>
                <p class="my-0">Nenhuma mensagem até o momento</p>
              </b-card>
            </b-card-text>
          </b-tab> -->
        </b-tabs>

        <div class="form-row justify-content-end modal-footer-custom">
          <div class="col-md-3 col-12">
            <ButtonsActionsFooter
              routerBack="customers-list"
              variant="success"
              :submited="submited"
              text="Salvar"
              subtext="Aguarde..."
            />
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BBadge,
  BFormGroup,
  BTooltip,
  BTabs,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BEmbed,
  BLink,
  BAlert,
  BTable,
  BTab,
  BAvatar,
  BMedia,
  BInputGroupAppend,
  BInputGroup,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import AppTimeline from "@/views/customer/customer/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@/views/customer/customer/components/app-timeline/AppTimelineItem.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import draggable from "vuedraggable";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import navigationMixin from "@/mixin/navigation";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BTab,
    BInputGroupAppend,
    BInputGroup,
    draggable,
    BEmbed,
    BTooltip,
    BCardText,
    BBadge,
    BFormInput,
    BFormGroup,
    flatPickr,
    vSelect,
    BForm,
    BRow,
    BCol,
    BLink,
    BAlert,
    ButtonsActionsFooter,
    BTable,
    BTabs,
    BAvatar,
    BMedia,
    BFormTextarea,
    BButton,
  },
  mixins: [togglePasswordVisibility, navigationMixin],
  data() {
    return {
      user: {},
      notFound: false,
      uuid: null,
      preview: null,
      image_url: "",
      submited: false,
      filter: null,
      filterOn: [],
      filteredOptions: [],
      wasUploads: 0,
      orders: [],
      loading: false,
      follow: {
        text: "",
      },
      item: {
        full_name: "",
        cpf: "",
        cellphone: "",
        email: "",
        password: "",
        password_confirmation: "",
        notes: "",
        address: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        cep: "",
      },
      streamingsOptions: [],
      teachersOptions: [],
      optionsProducts: [],
      optionsProductsGrid: [],
      product: "",
      grid: "",
      cart: "",
      fieldsLessons: [
        {
          key: "title",
          label: "Produto",
          sortable: false,
        },
      ],
      fieldsCart: [
        {
          key: "title",
          label: "Título",
          sortable: true,
        },
        {
          key: "price",
          label: "Preço",
          class: "text-center",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Data",
          class: "text-center",
          sortable: true,
          thStyle: "width: 230px",
        },
      ],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "method",
          label: "Título",
          sortable: true,
        },
        {
          key: "total",
          label: "Total",
          class: "text-center",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Produtos",
          class: "text-center",
          sortable: true,
        },
        {
          key: "classroom_name",
          label: "Turma",
          class: "text-center",
          sortable: true,
        },
        {
          key: "product_title",
          label: "Produto",
          class: "text-center",
          sortable: true,
        },
        {
          key: "product_category_name",
          label: "Categoria",
          class: "text-center",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Data",
          class: "text-center",
          sortable: true,
          thStyle: "width: 230px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 100px",
        },
      ],
      order: {
        note: "",
        products: [],
      },
      grid: {
        note: "",
        product: "",
        classroom: "",
      },
      interesteds: [],
      follows: [],
    };
  },
  validations: {
    item: {
      full_name: {
        required,
        minLength: minLength(5),
      },
      cpf: {
        required,
      },
      email: {
        required,
      },
      cellphone: {
        required,
      },
      password: {
        minLength: minLength(6),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
      address: {
        required,
      },
      number: {
        required,
      },
      neighborhood: {
        required,
      },
      city: {
        required,
      },
      uf: {
        required,
      },
      cep: {
        required,
      },
    },
    order: {
      note: {
        required,
        minLength: minLength(5),
      },
      products: {
        required,
      },
    },
    grid: {
      note: {
        required,
        minLength: minLength(5),
      },
      product: {
        required,
      },
      classroom: {
        required,
      },
    },
  },
  created() {
    this.uuid = this.$route.params.uuid;
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.getData();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    searchCep() {
      if (this.item.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.item.cep}/json/`)
          .then((res) => {
            const data = res.data;
            this.item.address = data.logradouro;
            this.item.neighborhood = data.bairro;
            this.item.city = data.localidade;
            this.item.uf = data.uf;
          })
          .catch((error) => console.log(error));
      }
    },
    typeDiscount(value, type) {
      return type === "percentage" ? `${value} %` : `R$ ${value}`;
    },
    async saveFollow() {
      this.loading = true;
      this.$store
        .dispatch("Customer/saveFollow", {
          customer_id: this.item.id,
          text: this.follow.text,
        })
        .then(() => {
          this.notifyDefault("success");
          this.follow.text = "";
          this.getData();
        })
        .catch(() => {
          this.notifyDefault("error");
        });
      this.loading = false;
    },
    getProductField(order, field) {
      if (Array.isArray(order.products) && order.products.length > 0) {
        return order.products[0][field] || "-";
      }
      return "-";
    },
    async getData() {
      this.$store
        .dispatch("Customer/show", this.$route.params.uuid)
        .then((res) => {
          this.item = res.data;

          this.orders = this.item.orders ?? [];
          this.interesteds = this.item.interesteds ?? [];
          this.follows = this.item.follows ?? [];

          if (this.item.cart) {
            this.cart = this.item.cart;
          }
        });
    },

    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },

    submitUpdate() {
      this.$v.item.$touch();
      this.submited = true;

      if (!this.$v.item.$error) {
        this.$store
          .dispatch("Customer/update", { uuid: this.uuid, data: this.item })
          .then(() => {
            this.$router.push({ name: "customers-list" });
            this.notifyDefault("success");
          })
          .catch(() => {
            this.submited = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
};
</script>

<style>
.beforeUpload .icon {
  width: 50px !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.app-timeline {
  .timeline-item {
    padding-bottom: 2.3rem !important;
  }

  .timeline-item:last-child {
    padding-bottom: 0px;
  }

  li:last-child {
    .text-timeline {
      margin-bottom: 0px;
    }
  }
}
</style>
